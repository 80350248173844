import React from "react";
import { CardBody } from "reactstrap";
import CreateMamProfile from "./CreateMamProfile";
import LinkInvestor from "./LinkInvestors";
import ConvertToSP from "./ConvertToSp";
import UnLinkInvestor from "./UnLinkInvestor";
import LinkSP from "./LinkSP";
import UnLinkSP from "./UnLinkSP";
import ConvertToInvestor from "./ConvertToInvestor";
import OfficialSp from "./OfficialSp";
const MamPamQuickActions = (props) => {
  const {
    clientDetails,
    clientId,
    isOfficialSp,
  } = props;

  return (
    <>
      {
        <CardBody className="quick-actions-card">
          <p className="quick-actions-heading">Copy Trading</p>
          <div className="btn-container">
            {
              clientDetails?.fx?.isSp && <>
                <CreateMamProfile clientId={clientId} />
                <LinkInvestor clientId={clientId} id={clientId} />
                <UnLinkInvestor clientId={clientId} id={clientId} />
                <OfficialSp clientId={clientId} isOfficialSp={clientDetails?.isOfficialSp} />
              </>
            }
            {
              !clientDetails?.fx?.isSp && <ConvertToSP
                id={clientId}
                isLead={clientDetails?.isLead}
              />
            }
          </div>
        </CardBody>
      }
      <CardBody className="quick-actions-card">
        <p className="quick-actions-heading">Investor</p>
        <div className="btn-container">
          {
            clientDetails?.fx?.isInvestor && <>
              <LinkSP
                id={clientId}
              />
              <UnLinkSP
                id={clientId}
              />
            </>
          }
          {
            !clientDetails?.fx?.isInvestor && <ConvertToInvestor
              isLead={clientDetails?.isLead}
              id={clientId}
            />
          }
        </div>
      </CardBody>
    </>
  );
};

export default (MamPamQuickActions);