import React from "react";
// eslint-disable-next-line object-curly-newline
import { Card, CardBody, Row } from "reactstrap";
import MamProfilesHeaderCol from "./MamProfilesHeaderCol";
import MamProfilesBodyCol from "./MamProfilesBodyCol";
import { deductionSchedule } from "common/mam-pamm";

const FeeType = ({ profile }) => {
  const schedule = deductionSchedule.find((item) => item.key === profile?.deductionSchedule)?.name ?? "-";
  return (
    <Card className="h-100">
      <CardBody className="d-flex flex-column gap-2">
        <Row className="text-center">
          <MamProfilesHeaderCol label="Fee Type" colNum={6} />
          <MamProfilesHeaderCol label="Value" colNum={6} />
        </Row>
        <Row className="text-center border border-dark">
          <MamProfilesBodyCol value={"Deduction Schedule Time"} colNum={6} />
          <MamProfilesBodyCol value={schedule} colNum={6} />
        </Row>
        <Row className="text-center border border-dark">
          <MamProfilesBodyCol value={"Performance Fee - (%)"} colNum={6} />
          <MamProfilesBodyCol value={profile?.feeSetting?.performanceFee} colNum={6} />
        </Row>
        <Row className="text-center border border-dark">
          <MamProfilesBodyCol value={"Management Fee - ($)"} colNum={6} />
          <MamProfilesBodyCol value={profile?.feeSetting?.managementFee} colNum={6} />
        </Row>
        <Row className="text-center border border-dark">
          <MamProfilesBodyCol value={"Annual Fee - ($)"} colNum={6} />
          <MamProfilesBodyCol value={profile?.feeSetting?.periodicManagementFee} colNum={6} />
        </Row>
        <Row className="text-center border border-dark">
          <MamProfilesBodyCol value={"Per Lot Fee - ($)"} colNum={6} />
          <MamProfilesBodyCol value={profile?.feeSetting?.perLotFee} colNum={6} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default FeeType;
