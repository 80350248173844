/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  fetchInvestorAccountRequests,
  investorAccountRequestApprove,
  investorAccountRequestReject,
} from "store/requests/actions";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { useTranslation } from "react-i18next";
import Badge from "components/Common/Badge";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import InvestorFilter from "./InvestorFilter";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProfileName } from "../Master/data";

function InvestorRequest(props) {
  const { t } = useTranslation();
  const { type } = useParams();
  const columns = [
    {
      dataField: "createdAt",
      text: t("Date"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "recordId",
      text: t("Request Id"),
    },
    {
      dataField: "type",
      text: t("Type"),
      formatter: (val) => {
          switch (val.type) {
            case "INVESTOR": return "Become Investor";
            case "INVESTOR_LINK": return "Investor Link";
            case "INVESTOR_UNLINK": return "Investor Unlink";
        }
      },
    },
    {
      dataField: "customerId",
      text: t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId },
              }}
            >
              <i className="no-italics fw-bold">
                {val.customerId
                  ? `${captilazeFirstLetter(
                      val?.customerId?.firstName
                    )} ${captilazeFirstLetter(val?.customerId?.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: t("Master Type"),
      formatter: (val) => {
        if (type === "link" || type === "unlink") {
          return (
            <div>
              {(getProfileName(val?.mamProfile?.profileType))}
            </div>
          );
        }
      },
    },
    {
      // dataField: "recordId",
      text: t("Investor Type"),
      formatter: (val) => {
        if (type === "link" || type === "unlink") {
          return (
            <div>
              {(val?.tradingAccount?.login)}
            </div>
          );
        }
      },
    },
    {
      dataField: "",
      text: t("Profile Master"),
      formatter: (val) => {
        return (
          <Link
            to={{
              pathname: `/clients/${val?.mamProfile?.customerId}/profile`,
            }}
            >{(val?.mamProfile?.customer?.[0]?.firstName || "") + " " + (val?.mamProfile?.customer?.[0]?.lastName || "")}</Link>
        );
        }
    },
    {
      dataField: "",
      text: t("Client Status"),
      formatter: (val) => {
        return (
          <div>
            {(source => {
              switch (source) {
                case "INVESTOR_LIVE": return "New";
                default : return "Existing";
            }})(val?.customer?.source)}
          </div>
        );
      }
    },
    {
      dataField: "kyc",
      text: t("KYC Status"),
      formatter: (item) => {
        if (!item?.customerId?.stages) return "Pending";
        const { kycApproved, kycRejected } = item?.customerId?.stages;
        return (<div>
          <i className={kycApproved ? "text-success" : kycRejected ? "text-danger" : "text-warning"}>
            <i
              className={`mdi font-size-20 ${kycApproved ? "mdi-check-circle" : kycRejected ? "mdi-close-thick" : "mdi-alert-decagram-outline"}`}
              id={kycApproved ? "approve-icon" : kycRejected ? "reject-icon" : "pending-icon"}
            ></i>
          </i>
        </div>);
      }
    },
    {
      dataField: "status",
      text: t("Status"),
      formatter: (user) => <Badge status={user.status} />,
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: t("Actions"),
      formatter: (item) => (
        <UncontrolledDropdown
          disabled={
            !props.requestsPermissions.actions ||
            item?.status === "APPROVED" ||
            item?.status === "REJECTED"
              ? true
              : false
          }
        >
          <DropdownToggle
            tag="i"
            className="text-muted"
            style={{ cursor: "pointer" }}
          >
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              style={{
                color:
                  item?.status === "APPROVED" || item?.status === "REJECTED"
                    ? "lightgray"
                    : "rgb(66, 65, 65)",
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              onClick={() => {
                investorRequestApprove(item?._id);
              }}
              href="#"
            >
              {t("Approve")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                investorRequestRejected(item?._id);
              }}
              href="#"
            >
              {t("Reject")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
  const [sizePerPage, setSizePerPage] = useState(10);

  const initFilteredValues = {
    customerId: "",
    filterDate: {
      fromDate: "",
      toDate: "",
    },
    status: "",
    agent: "",
    "content.login": "",
    "content.from": "",
    "content.to": "",
    "content.platform": "",
  };
  const [filteredValues, setFilteredValues] = useState(initFilteredValues);
  const filterChangeHandler = (filteredValuesData) => {
    setFilteredValues(filteredValuesData);
  };

  useEffect(() => {
    setFilteredValues(initFilteredValues);
  }, [type]);

  const dispatch = useDispatch();
  useEffect(() => {
    loadInvestersRequests(1, sizePerPage);
  }, [
    sizePerPage,
    1,
    props.isApproveOrReject,
    filteredValues,
    props.clearingCounter,
    type,
  ]);

  const getPageType = () => {
    switch (type) {
      case "requests": return "INVESTOR";
      case "link": return "INVESTOR_LINK";
      case "unlink": return "INVESTOR_UNLINK";
      default: return "";
    }
  };

  const loadInvestersRequests = (page, limit) => {
    dispatch(
      fetchInvestorAccountRequests({
        page,
        limit,
        filteredValues,
        type: getPageType()
      })
    );
  };

  const formatType = () => getPageType()?.toLowerCase()?.split("_").map(captilazeFirstLetter).join(" ");

  const investorRequestApprove = (id) => {
    dispatch(investorAccountRequestApprove({
      id,
      type: getPageType(),
    }));
  };
  const investorRequestRejected = (id) => {
    dispatch(investorAccountRequestReject({
      id,
      type: getPageType(),
    }));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{formatType()}  Request</title>
        </MetaTags>
        <div className="container-fluid">
          <h2>{t( formatType() + " Request")}</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">
                    {t(formatType() + " Request")} ({props.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        loadInvestersRequests(1, sizePerPage);
                      }}
                    />
                  </CardTitle>
                  <InvestorFilter
                    filterChangeHandler={filterChangeHandler}
                    filteredValues={filteredValues}
                  />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {columns.map((column, index) => (
                              <Th data-priority={index} key={index}>
                                {column.text}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody
                          className="text-center"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {props.loading && <TableLoader colSpan={4} />}
                          {!props.loading &&
                            props.docs.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {columns.map((column, index) => (
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))}
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadInvestersRequests}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.requestReducer.loading || false,
  docs: state.requestReducer.docs || [],
  changeStatusLoading: state.requestReducer.changeStatusLoading,
  changeStatusLoadingIndex: state.requestReducer.changeStatusLoadingIndex,
  page: state.requestReducer.page || 1,
  totalDocs: state.requestReducer.totalDocs || 0,
  totalPages: state.requestReducer.totalPages || 0,
  hasNextPage: state.requestReducer.hasNextPage,
  hasPrevPage: state.requestReducer.hasPrevPage,
  limit: state.requestReducer.limit,
  nextPage: state.requestReducer.nextPage,
  pagingCounter: state.requestReducer.pagingCounter,
  prevPage: state.requestReducer.prevPage,
  deleteLoading: state.requestReducer.deleteLoading,
  deleteClearingCounter: state.requestReducer.deleteClearingCounter,
  requestsPermissions: state.Profile.requestsPermissions || {},
  isApproveOrReject: state.requestReducer.isApproveOrReject,
  clearingCounter: state.requestReducer.clearingCounter,
});
export default connect(mapStateToProps, null)(InvestorRequest);
