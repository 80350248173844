import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import SearchableSP from "./SearchableComponents/SearchableSP";
import { AvForm } from "availity-reactstrap-validation";
import SearchableProfile from "./SearchableComponents/SearchableProfile";
import AvFieldSelect from "components/Common/AvFieldSelect";
import SearchableInvestorAccount from "./SearchableComponents/SearchableInvestorAccounts";
import { linkInvestorToProfile } from "store/mam-pam/actions";
import { fetchClientDetails } from "store/client/actions";

function LinkSP({ isLead, kyc = true, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [linkClientModal, setLinkClientModal] = useState(false);
  const [sp, setSP] = useState();
  const [profile, setProfile] = useState();
  const [type, setType] = useState("new");
  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
    cleanUp();
  };

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    setSP();
    setProfile();
  };

  const handleConfirm = (e, v) => {
    dispatch(linkInvestorToProfile({
      investorId: v?.investorAccount?._id,
      profileId: profile?._id,
      type,
      customerId: id,
    }));
  };

  const {
    link: { loading },
    clearingCounter,
  } = useSelector((state) => state.mamPamReducer);

  useEffect(() => {
    if (linkClientModal) {
      dispatch(fetchClientDetails(id));
      toggleModal();
    }
  }, [clearingCounter]);

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Link SP")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <AvForm
          onValidSubmit={(e, v) => handleConfirm(e, v)}
        >
          <ModalHeader toggle={toggleModal}>
            {t("Link Investor to SP")}
          </ModalHeader>
          <ModalBody >
            <Row>
              <Col>
                <SearchableSP
                  sp={sp}
                  setSP={setSP}
                />
              </Col>
            </Row>
            {
              sp && (<>
                <Row>
                  <Col>
                    <SearchableProfile
                      customerId={sp?._id}
                      profile={profile}
                      setProfile={setProfile}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvFieldSelect
                      name="type"
                      label={t("Type")}
                      options={[
                        {
                          label: t("Existing"),
                          value: "existing",
                        },
                        {
                          label: t("New Account"),
                          value: "new",
                        }
                      ]}
                      value={type}
                      required
                      onChange={(e) => {
                        setType(e);
                      }}
                    />
                  </Col>
                </Row>
                {
                  type === "existing" && id && <>
                    <SearchableInvestorAccount investor={{
                      _id: id
                    }} />
                  </>
                }
              </>)
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary">
              {t("No")}
            </Button>
            {loading ? <Loader /> : (
              <Button type="submit" color="primary" disabled={!kyc || !sp  || !profile}>
                {t("Yes")}
              </Button>
            )}
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
}

export default (LinkSP);