import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import { AvForm } from "availity-reactstrap-validation";
import SearchableInvestor from "./SearchableComponents/SearchableInvestor";
import SearchableProfile from "./SearchableComponents/SearchableProfile";
import AvFieldSelect from "components/Common/AvFieldSelect";
import SearchableInvestorAccount from "./SearchableComponents/SearchableInvestorAccounts";
import { linkMasterToInvestor } from "store/mam-pam/actions";
import { fetchClientDetails } from "store/client/actions";

function LinkInvestor({ isLead, kyc = true, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [investor, setInvestor] = useState();
  const [profile, setProfile] = useState();
  const {
    link: { loading },
    clearingCounter,
  } = useSelector((state) => state.mamPamReducer);

  const [linkClientModal, setLinkClientModal] = useState(false);
  const [type, setType] = useState("new");
  const toggleModal = () => {
    setInvestor();
    setLinkClientModal(!linkClientModal);
  };

  const handleConfirm = (e, v) => {
    dispatch(linkMasterToInvestor({
      investorId: v?.investorAccount?._id,
      profileId: v?.profile?._id,
      type: type,
      customerId: id,
    }));
  };

  useEffect(() => {
    if (linkClientModal) {
      dispatch(fetchClientDetails(id));
      toggleModal();
    }
  }, [clearingCounter]);

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Link Investor")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <AvForm
          onValidSubmit={(e, v) => handleConfirm(e, v)}
        >
          <ModalHeader toggle={toggleModal}>
            {t("Link Investors to SP")}
          </ModalHeader>
          <ModalBody >
            <Row>
              <Col>
                <SearchableProfile
                  customerId={id}
                  profile={profile}
                  setProfile={setProfile}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SearchableInvestor
                  investor={investor}
                  setInvestor={setInvestor}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AvFieldSelect
                  name="type"
                  label={t("Type")}
                  options={[
                    {
                      label: t("Existing"),
                      value: "existing",
                    },
                    {
                      label: t("New Account"),
                      value: "new",
                    }
                  ]}
                  value={type}
                  required
                  onChange={(e) => {
                    setType(e);
                  }}
                />
              </Col>
            </Row>
            {
              type === "existing" && investor && <>
                <SearchableInvestorAccount investor={investor} />
              </>
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary">
              {t("No")}
            </Button>
            {loading ? <Loader /> : (
              <Button type="submit" color="primary" disabled={!kyc || !investor}>
                {t("Yes")}
              </Button>
            )}
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
}

export default (LinkInvestor);