import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  CREATE_MAM_PROFILE_START,
  FETCH_MAM_PROFILE_START,
  LINK_INVESTOR_TO_PROFILE_START,
  LINK_MASTER_TO_INVESTOR_START,
  UNLINK_INVESTOR_FROM_PROFILE_START,
  UNLINK_MASTER_FROM_INVESTOR_START,
} from "./actionTypes";
import {
  createMamProfileFail,
  createMamProfileSuccess,
  fetchMamProfilesFail,
  fetchMamProfilesSuccess,
  linkInvestorToProfileFail,
  linkInvestorToProfileSuccess,
  linkMasterToInvestorFail,
  linkMasterToInvestorSuccess,
  unlinkInvestorFromProfileFail,
  unlinkInvestorFromProfileSuccess,
  unlinkMasterFromInvestorFail,
  unlinkMasterFromInvestorSuccess
} from "./actions";
import {
  createProfileAPI,
  fetchMamPamAPI,
  linkMamAPI,
  unlinkMamAPI
} from "apis/mam-profile";

function* getMamPamProfiles({ payload }) {
  try {
    const { docs } = yield call(fetchMamPamAPI, payload);
    yield put(fetchMamProfilesSuccess(docs));
  } catch (error) {
    yield put(fetchMamProfilesFail(error));
  }
}

function* createMamPamProfile({ payload }) {
  try {
    const result = yield call(createProfileAPI, payload);
    yield put(createMamProfileSuccess(result));
    yield put(showSuccessNotification("MamPam Profile created successfully"));
  } catch (error) {
    yield put(createMamProfileFail(error));
    yield put(showErrorNotification("MamPam Profile creation failed"));
  }
}

function* linkInvestorToProfile({ payload }) {
  try {
    const result = yield call(linkMamAPI, payload);
    yield put(linkInvestorToProfileSuccess(result));
    yield put(showSuccessNotification("Investor linked to profile successfully"));
  } catch (error) {
    yield put(linkInvestorToProfileFail(error));
    yield put(showErrorNotification("Investor linking to profile failed"));
  }
}

function* linkMasterToInvestor({ payload }) {
  try {
    const result = yield call(linkMamAPI, payload);
    yield put(linkMasterToInvestorSuccess(result));
    yield put(showSuccessNotification("Master linked to investor successfully"));
  } catch (error) {
    yield put(linkMasterToInvestorFail(error));
    yield put(showErrorNotification("Master linking to investor failed"));
  }
}

function* unlinkInvestorFromProfile({ payload }) {
  try {
    const result = yield call(unlinkMamAPI, payload);
    yield put(unlinkInvestorFromProfileSuccess(result));
    yield put(showSuccessNotification("Investor unlinked from profile successfully"));
  } catch (error) {
    yield put(unlinkInvestorFromProfileFail(error));
    yield put(showErrorNotification("Investor unlinking from profile failed"));
  }
}

function* unlinkMasterToInvestor({ payload }) {
  try {
    const result = yield call(unlinkMamAPI, payload);
    yield put(unlinkMasterFromInvestorSuccess(result));
    yield put(showSuccessNotification("Master unlinked from investor successfully"));
  } catch (error) {
    yield put(unlinkMasterFromInvestorFail(error));
    yield put(showErrorNotification("Master unlinking from investor failed"));
  }
}

function* mamPamSaga() {
  yield takeEvery(FETCH_MAM_PROFILE_START, getMamPamProfiles);
  yield takeEvery(CREATE_MAM_PROFILE_START, createMamPamProfile);
  yield takeEvery(LINK_INVESTOR_TO_PROFILE_START, linkInvestorToProfile);
  yield takeEvery(LINK_MASTER_TO_INVESTOR_START, linkMasterToInvestor);
  yield takeEvery(UNLINK_INVESTOR_FROM_PROFILE_START, unlinkInvestorFromProfile);
  yield takeEvery(UNLINK_MASTER_FROM_INVESTOR_START, unlinkMasterToInvestor);
}

export default mamPamSaga;