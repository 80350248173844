import { getTradingAccountByCustomerId } from "apis/tradingAccounts";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React, { useState } from "react";

export default function SearchableInvestorAccount({
  investor, setAccount
}) {
  const [acc, setAcc] = useState();
  return (
    <SearchableComponent
      placeholder={("Select Investor Account")}
      label={"Investor Account"}
      name={"investorAccount"}
      onChange={(e) => {
        if (setAccount) {
          if (e?.value) {
            setAcc(e.value);
            setAccount(e.value);
          } else {
            setAcc(e);
            setAccount(e);
          }
        } else {
          e?.value ? setAcc(e.value) : setAcc(e);
        }
      }}
      getData={
        async () => getTradingAccountByCustomerId({ 
          payload: {
            customerId: investor?._id,
            page: 1,
            limit: 1000,
          }
        }).then((res) => (res?.result?.docs || []))
      }
      mapper={(doc)=> (
        {
          label: `${doc.login} | ${doc.platform} | ${doc?.accountTypeId?.title}`,
          value: doc
        }
      )}
      value={acc}
    />
  );
}
