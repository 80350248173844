import logo from "assets/images/brands/vtindexpro.png";
import sidebarlogo from "assets/images/brands/vtindexpro-sidebar.png";
import lLogo from "assets/images/brands/vtindexpro.png";

export const clientName = "VTINDEXPRO";
export const developedBy = "Exinitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "Vtindexpro";
export const sidebarLogo = sidebarlogo;
export const mainLogo = logo;
export const lightLogo = lLogo;
