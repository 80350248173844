export const deductionSchedule = [
  {
    key: 1,
    name: "Daily"
  },
  {
    key: 2,
    name: "Weekly"
  },
  {
    key: 3,
    name: "Monthly"
  },
  {
    key: 4,
    name: "Quarterly"
  },
  {
    key: 6,
    name: "Annually"
  },
];
export const currency = [
  {
    key: "USD",
    name: "USD"
  }
];
export const platform = [
  {
    key: "MT5",
    name: "MT5"
  },
];
export const profiles = [
  {
    label: "Equity Lot Share - MAM",
    value: 1,
  },
  {
    label: "Equity Cash Share - PAM",
    value: 2,
  },
];

export const getProfileName = (key) => {
  const profile = profiles.find((item) => item.value === key);
  return profile ? profile.label : "";
};

export const getDeductionScheduleName = (key) => {
  const schedule = deductionSchedule.find((item) => item.key === key);
  return schedule ? schedule.name : "";
};